import * as React from 'react';
import MaterialTable from "@material-table/core";
import { useFetchApi } from '../utils/UseFetchApi'
import { ClusterLink, clusterHasTrouble } from './ClusterDetails'
import { enqueueSnackbar } from 'notistack';
import DeleteClusterDialog from './DeleteClusterDialog';

interface IJobStatistics {
    active: number;
    completed: number;
}

export enum ClusterState {
    Steady = "Steady",
    Resizing = "Resizing",
    Stopping = "Stopping"
}
interface INodeStatistics {
    idle: number;
    running: number;
    starting: number;
    rebooting: number;
    start_task: number;
    start_task_failed: number;
    leaving: number;
    unusable: number;
    preempted: number;
    other: number;
}

interface IDeleteConfirmationState {
    isOpen: boolean;
    cluster?: IClusterInfo;
    isDeleteAllDisabled: boolean;
    duplicateClusters?: IClusterInfo[];
}

export interface IClusterInfo {
    name: string;
    dedicatedNodes: number;
    lowPriorityNodes: number;
    state: ClusterState;
    location: string;
    jobStatistics: IJobStatistics;
    nodeStatistics: INodeStatistics;
}

export default function Clusters() {
    const clustersApi = useFetchApi<IClusterInfo[]>(window.location.origin + "/api/v2/clusters");
    const fetchApi = useFetchApi<string>();
    const [deleteConfirmation, setDeleteConfirmation] = React.useState<IDeleteConfirmationState>({
        isOpen: false,
        isDeleteAllDisabled: false,
        duplicateClusters: [],
    });

    React.useEffect(() => {
        clustersApi.run();
    }, []);

    function handleDeleteConfirmation(cluster: IClusterInfo) {
        const duplicateClusters = findDuplicateClusters(clustersApi.data ?? [], cluster);

        const nonResizingDuplicateClusters = duplicateClusters.filter(
            duplicateCluster => duplicateCluster.state !== ClusterState.Resizing
        );

        const filteredDuplicateClusters = nonResizingDuplicateClusters.filter(
            duplicateCluster => duplicateCluster.name !== cluster.name || duplicateCluster.location !== cluster.location
        );

        if (nonResizingDuplicateClusters.length > 0) {
            setDeleteConfirmation({ isOpen: true, cluster, isDeleteAllDisabled: false, duplicateClusters: filteredDuplicateClusters });
        } else {
            setDeleteConfirmation({ isOpen: true, cluster, isDeleteAllDisabled: true });
        }
    }

    async function handleDeletion(clusters: IClusterInfo[]) {
        try {
            // filtering out clusters in resizing state
            const clustersToDelete = clusters.filter(cluster => cluster.state !== ClusterState.Resizing);

            const response = await fetchApi.run(window.location.origin + `/api/v2/clusters/locations/delete`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clustersToDelete.map(cluster => ({
                    location: cluster.location,
                    clusterId: cluster.name
                })))
            });

            if (response) {
                clustersApi.run(); // refreshes clusters data
                setDeleteConfirmation({ isOpen: false, isDeleteAllDisabled: false });
                const variant = response.toLowerCase().includes('failed') ? 'info' : 'success';
                enqueueSnackbar(response, { variant });
            }
        } catch (error) {
            enqueueSnackbar(`Error deleting cluster: ${(error instanceof Error) ? error.message : 'Unknown error'}`, { variant: "error" });
        }
    }

    async function handleDeleteConfirmed(cluster: IClusterInfo) {
        await handleDeletion([cluster]);
    }

    async function handleDeleteAllConfirmed() {
        const { cluster, duplicateClusters } = deleteConfirmation;

        const clustersToDelete = [cluster!];
        if (duplicateClusters && duplicateClusters.length) {
            clustersToDelete.push(...duplicateClusters);
        }

        await handleDeletion(clustersToDelete);
    }

    function handleCloseConfirmation() {
        setDeleteConfirmation({ isOpen: false, isDeleteAllDisabled: false });
    }

    function findDuplicateClusters(clusters: IClusterInfo[], clusterToDelete: IClusterInfo): IClusterInfo[] {
        return clusters.filter(cluster => cluster.name === clusterToDelete.name && cluster.location !== clusterToDelete.location);
    }

    function getTrouble(cluster: IClusterInfo) {
        if (clusterHasTrouble(cluster))
            return "⚠️"
        return "✔️"
    }

    const pageSize = 10, pageSizeOptions = [5, 10, 20];

    return (
        <div>
            <MaterialTable<IClusterInfo>
                title="Clusters"
                isLoading={clustersApi.isFetching}
                columns={[
                    { title: "Name", field: "name", sorting: true, render: rowData => <ClusterLink cluster={rowData} />  },
                    { title: "Location", field: "location", },
                    {
                        title: "Total nodes (low priority)", field: "lowPriorityNodes",
                        render: rowData => <div>{rowData.dedicatedNodes + rowData.lowPriorityNodes} ({rowData.lowPriorityNodes})</div>
                    },
                    { title: "Jobs Active", field: "jobStatistics.active" as any },
                    { title: "Jobs Completed", field: "jobStatistics.completed" },
                    { title: "State", field: "state", },
                    {
                        title: "Trouble?", field: "trouble",
                        render: rowData => <div>{getTrouble(rowData)}</div>,
                        sorting: false
                    }
                ]}
                data={clustersApi.data ?? []}
                actions={[
                    {
                        tooltip: 'Refresh',
                        icon: 'refresh',
                        isFreeAction: true,
                        onClick: () => clustersApi.run(),
                    },
                    rowData => ({ 
                        tooltip: 'Delete cluster',
                        icon: 'delete',
                        disabled: rowData.state.toString().toLowerCase() !== ClusterState[ClusterState.Steady].toLowerCase(),
                        onClick: (_event, rowData) => handleDeleteConfirmation(rowData as IClusterInfo),
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    sorting: true,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptions,
                    headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />

            <DeleteClusterDialog
                isOpen={deleteConfirmation.isOpen}
                cluster={deleteConfirmation.cluster}
                isDeleteAllDisabled={deleteConfirmation.isDeleteAllDisabled}
                duplicateClusters={deleteConfirmation.duplicateClusters}
                onClose={handleCloseConfirmation}
                onDeleteConfirmed={() => handleDeleteConfirmed(deleteConfirmation.cluster as IClusterInfo)}
                onDeleteAllConfirmed={handleDeleteAllConfirmed}
            />

        </div>
    );
}
