import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { ClusterState, IClusterInfo } from './Clusters';

const StyledButton = styled(Button)((props) => ({
    marginLeft: props.theme.spacing(2),
    marginTop: props.theme.spacing(2),
}));

const StyledButtonGroup = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
        marginLeft: 10,
        marginTop: 10,
    }
})

const StyledDialogContent = styled(DialogContent)({
    paddingBottom: 0
})

const StyledDialogActions = styled(DialogActions)({
    marginBottom: 20
})

const StyledDialogBoxStatement = styled('p')({
    marginTop: 5,
    marginBottom: 5
})

interface IDeleteClusterDialogProps {
    isOpen: boolean;
    cluster?: IClusterInfo;
    isDeleteAllDisabled: boolean;
    duplicateClusters?: IClusterInfo[];
    onClose: () => void;
    onDeleteConfirmed: () => void;
    onDeleteAllConfirmed: () => void;
}

const DeleteClusterDialog: React.FC<IDeleteClusterDialogProps> = ({
    isOpen,
    cluster,
    isDeleteAllDisabled,
    duplicateClusters,
    onClose,
    onDeleteConfirmed,
    onDeleteAllConfirmed,
}) => {

    // filtering out the locations where the state is resizing
    const filteredLocations = duplicateClusters?.filter(
        duplicateCluster => duplicateCluster.state !== ClusterState.Resizing
    );
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Delete Cluster</DialogTitle>
            <StyledDialogContent>
                {cluster && (
                    <>
                        <p>
                            {`You have selected the cluster `}
                            <strong>{cluster.name}</strong>
                            {` from `}
                            <strong>{cluster.location}</strong>.
                        </p>
                        {filteredLocations && filteredLocations.length > 0 && (
                            <>
                                <StyledDialogBoxStatement>
                                    {`Do you also want to delete cluster(s) `}
                                    <strong>{cluster.name}</strong>
                                    {` from the following locations?`}
                                </StyledDialogBoxStatement>
                                <ul>
                                    {filteredLocations.map(duplicateCluster => (
                                        <li key={duplicateCluster.location}>{duplicateCluster.location}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {(!filteredLocations || filteredLocations.length === 0) && (
                            <StyledDialogBoxStatement>
                                {`Are you sure you want to delete `}
                                <strong>{cluster.name}</strong>
                                {`?`}
                            </StyledDialogBoxStatement>
                        )}
                    </>
                )}
            </StyledDialogContent>
            <StyledDialogActions>
                <StyledButtonGroup>
                    {!isDeleteAllDisabled && (
                        <StyledButton onClick={onDeleteAllConfirmed} variant="contained" color="secondary">
                            Delete all
                        </StyledButton>
                    )}
                    <StyledButton onClick={onDeleteConfirmed} variant="contained" color="secondary">
                        {isDeleteAllDisabled ? 'Delete' : 'Delete selected'}
                    </StyledButton>
                    <StyledButton onClick={onClose} variant="contained" color="primary">
                        Cancel
                    </StyledButton>
                </StyledButtonGroup>
            </StyledDialogActions>
        </Dialog>
    );
};

export default DeleteClusterDialog;
