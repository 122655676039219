import * as React from 'react';
import MaterialTable, { Action } from "@material-table/core";
import { IJob, JobLink, JobState, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';
import useInterval from '../utils/UseInterval';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Progress from '../utils/ProgressUtils';
import { EmailEllipsis, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { useAuthorizationContext } from '../utils/AuthorizationContext';
import { ActiveJobsRefreshInterval } from "../utils/Constants";
import ScheduleSharpIcon from '@mui/icons-material/ScheduleSharp';
import { Box, Tooltip } from '@mui/material';
import { ISelectorOptions, Selector } from 'realityservices-ui-components';

enum ActiveJobFilter {
    ShowAll = "ShowAll",
    ShowQueuedOnly = "ShowQueuedOnly",
    HideQueued = "HideQueued"
}

const activeJobFilterOptions: ISelectorOptions[] = [
    {
        label: "Show all",
        value: ActiveJobFilter.ShowAll
    },
    {
        label: "Show queued jobs only",
        value: ActiveJobFilter.ShowQueuedOnly
    },
    {
        label: "Hide queued jobs",
        value: ActiveJobFilter.HideQueued
    },
];

interface ISetAutoRefreshProps{
    toggleTabRefresh: boolean
}

export default function ActiveJobs(props:ISetAutoRefreshProps) {
    const jobReport = useJobReport(`state=${JobState.Active}&state=${JobState.Queued}`, true);
    const jobActions = useJobActions();
    const [autoRefresh, setAutoRefresh] = React.useState(props.toggleTabRefresh);
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);
    const [activeJobFilter, setActiveJobFilter] = React.useState<ActiveJobFilter>(activeJobFilterOptions[0].value);

    React.useEffect(() => {
        if(autoRefresh===false ) //Trick to get refresh just by one variable on tab change
        {
            jobReport.refresh();
        }
        setAutoRefresh(props.toggleTabRefresh)
    },[props.toggleTabRefresh]);

    function toggleAutoRefresh() {
        if (autoRefresh) {
            setAutoRefresh(false);
        }
        else {
            setAutoRefresh(true);
            jobReport.refresh()
        }
    }

    function getEstimatedUnits(job: IJob): string {
        if (job.executionInformation?.inputInformation == null)
            return "-";
        const inputInfo = job.executionInformation?.inputInformation;
        const units = inputInfo.gigaPixelsAT + inputInfo.megaPoints;
        return (Math.round(units * 100) / 100).toString();
    }

    function getTableActions() {
        let actions: (Action<IJob> | ((rowData: IJob) => Action<IJob>))[] = [
            {
                icon: () =>
                    autoRefresh ? (
                        <PauseCircleOutlineIcon />
                    ) : (
                        <PlayCircleOutlineIcon />
                    ),
                tooltip: "Auto Refresh",
                isFreeAction: true,
                onClick: () => toggleAutoRefresh(),
            },
        ];

        if (isAdminOrViewOnlyUser) {
            actions.push(
                {
                    tooltip: "Download logs",
                    icon: "get_app",
                    onClick: (_, rowData) => jobActions.downloadLogs(rowData),
                },
                {
                    tooltip: "Terminate Job",
                    icon: "cancel",
                    onClick: (_, rowData) => jobActions.terminate(rowData),
                }
            );
        }

        return actions;
    }

    useInterval(() => {
        if (jobReport.error) {
            setAutoRefresh(false);
            return;
        }

        jobReport.refresh();
    }, autoRefresh ? ActiveJobsRefreshInterval : null);

    function renderJobProgress(job: IJob) {
        if (job.state === JobState.Queued) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Tooltip title={job.state}>
                        <ScheduleSharpIcon />
                    </Tooltip>
                </Box>
            );
        }

        return (
            <Progress
                jobId={job.id}
                autoRefresh={autoRefresh}
            />
        );
    }

    function getTableTitle() {
        if (jobReport.data?.some(j => j.state === JobState.Queued)) {
            return (
                <Selector
                    default={activeJobFilterOptions[0]}
                    options={activeJobFilterOptions}
                    onValueChanged={(val) => setActiveJobFilter(val)}
                />
            );
        }

        return "";
    }

    function getFilteredJobs(): IJob[] {
        let filteredJobs = jobReport.data ?? [];

        if (activeJobFilter === ActiveJobFilter.ShowQueuedOnly) {
            filteredJobs = filteredJobs.filter(j => j.state === JobState.Queued);
        }

        if (activeJobFilter === ActiveJobFilter.HideQueued) {
            filteredJobs = filteredJobs.filter(j => j.state !== JobState.Queued);
        }

        return filteredJobs;
    }

    return (
        <div>
            <MaterialTable<IJob>
                title={getTableTitle()}
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={[
                    {
                        title: "Progress",
                        field: "id",
                        render: renderJobProgress,
                        sorting: false,
                        filtering: false,
                        editable: "never",
                        width: "auto",
                        align: "center",
                    },
                    {
                        title: "Name",
                        field: "name",
                        sorting: true,
                        render: (rowData) => (
                            <JobLink id={rowData.id} name={rowData.name} />
                        ),
                    },
                    { title: "Type", field: "type", sorting: true },
                    {
                        title: "Email",
                        field: "userDetails.email" as any,
                        sorting: true,
                        cellStyle: { width: "150px", maxWidth: "150px" },
                        headerStyle: { width: "150px", maxWidth: "150px" },
                        render: (rowData) => (
                            <EmailEllipsis email={rowData.userDetails.email} />
                        ),
                    },
                    createDateColumn(
                        "Submission",
                        "submissionDetails.time",
                        "desc"
                    ),
                    createDateColumn("Start", "executionInformation.startTime"),
                    {
                        title: "Location",
                        field: "dataCenter.location",
                        sorting: true,
                    },
                    {
                        title: "Size",
                        field: "executionInformation.inputInformation",
                        render: (rowData) => getEstimatedUnits(rowData),
                        sorting: true,
                        filtering: false,
                        editable: "never",
                    },
                ]}
                data={getFilteredJobs()}
                actions={getTableActions()}
                options={{
                    filtering: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    search: true,
                    sorting: true,
                    headerStyle: {
                        backgroundColor: jobReport.error
                            ? "#FF0000"
                            : "#9BA5AE",
                        color: "#000000",
                    },
                    padding: "dense",
                    maxBodyHeight: "63vh",
                    rowStyle: {
                        fontSize: "0.875rem",
                    },
                }}
            />
        </div>
    );

}


